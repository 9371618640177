import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Overview from "./OverView/Overview";
import Section from "./Cirriculum/Cirriculum";
import Instructor from "./Instructor/Instructor";
import FAQs from "./FAQs/FAQs";
import classes from "./tabs.module.css";
import Review from "./Reviews/Review";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ course }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "823px",
        overflowY: "auto",
        backgroundColor:
          "linear-gradient(45deg, rgb(30, 2, 62) 4%, rgb(26, 14, 56) 69%)",

        // boxShadow: "0.5px 0.5px 3px 0.5px rgba(225, 225, 225, 0.779)",
        boxShadow: "0.5px 0.5px 3px 2px rgba(24, 57, 108, 1)",
      }}
    >
      {" "}
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          maxWidth: { xs: 380, sm: "100%" },
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto" // Use "auto" for responsive scrolling
          allowScrollButtonsMobile
          aria-label="scroll"
          indicatorColor="primary"
          indicator={<Box sx={{ borderBottom: 2, borderColor: "#6C77A1" }} />} // Add this prop to customize the indicator line
          sx={{ color: "white" }}
        >
          <Tab
            label="Overview"
            {...a11yProps(0)}
            sx={{
              color: "white",
              "&.Mui-selected": {
                color: "#6C77A1",
              },
            }}
          />
          <Tab
            label="Curriculum"
            {...a11yProps(1)}
            sx={{
              color: "white",
              "&.Mui-selected": {
                color: "#6C77A1",
              },
            }}
          />
          <Tab
            label="Instructor"
            {...a11yProps(2)}
            sx={{
              color: "white",
              "&.Mui-selected": {
                color: "#6C77A1",
              },
            }}
          />
          {course?.reviews.length !== 0 && (
            <Tab
              label="Reviews"
              {...a11yProps(3)}
              sx={{
                color: "white",
                "&.Mui-selected": {
                  color: "#6C77A1",
                },
              }}
            />
          )}

          <Tab
            label="FAQs"
            {...a11yProps(4)}
            sx={{
              color: "white",
              "&.Mui-selected": {
                color: "#6C77A1",
              },
            }}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Overview course={course} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {course?.syllabus?.map((section, i) => (
          <Section
            index={i}
            key={i}
            title={section?.chapterTitle}
            content={section?.chapterContent}
          />
        ))}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Instructor
          imageUrl={course?.mentor?.profilePicture}
          instructorName={course?.mentor?.fullName}
          course={course?.mentor?.courses}
          instructorDescription={course?.mentor?.bio}
          field={course?.mentor?.major}
          linkedUrl={course?.mentor?.linkedIn}
          github={course?.mentor?.github}
          twitterUrl={course?.mentor?.twitter}
          instaUrl={course?.mentor?.instagram}
          totalReviews={course?.mentor?.totalReviews}
          totalStudents={course?.mentor?.totalStudent}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        {course?.reviews.map((review, i) => (
          <Review
            key={i}
            imageUrl={review?.userImage}
            name={review?.user}
            rating={review?.rating}
            text={review?.comment}
          />
        ))}
      </TabPanel>
      <TabPanel value={value} index={4}>
        <div className={classes.header}>
          <h2>Frequently Asked Questions</h2>
        </div>
        {course?.faqs.map((element, i) => (
          <FAQs element={element} key={i} />
        ))}
      </TabPanel>
    </Box>
  );
}
