import React from "react";
import "./team.css";
import { FaInstagram, FaLinkedin, FaWhatsapp } from "react-icons/fa";

const Team = (props) => {
  const { avatar, name, position, description, socialMedia } = props;

  return (
    <div className="card">
      <div className="card-info">
        <div className="card-avatar">
          <img src={avatar} height={100} width={100} alt="avatar" />
        </div>
        <div className="card-title">{name}</div>
        <div className="card-subtitle">{position}</div>
      </div>
      <div className="cardDescription">
        <p>{description}</p>
      </div>
      <ul className="card-social">
        {socialMedia.map((platform, index) => (
          <li className="card-social__item" key={index}>
            <a href={platform.link}  target="_blank" rel="noreferrer" >
              {platform.icon === "instagram" && <FaInstagram />}
              {platform.icon === "whatsapp" && <FaWhatsapp />}
              {platform.icon === "linkedin" && <FaLinkedin />}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Team;
