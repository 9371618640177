import React, { useState, useEffect } from "react";
import classes from "./mentor.module.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import axios from "../../axios/axios";
import { toast, ToastContainer } from "react-toastify";
import DOMPurify from "dompurify";
import { FormControl, FormLabel, MenuItem, Select } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Mentor = () => {
  const textareaStyles = {
    resize: "none",
  };

  const navigate = useNavigate();

  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    expertise: "",
    courseName: "",
    experience: "", //senior lead manager founder
    highestEducation: "",
    Availability: "", //full time Part-time Weekends only Evenings only
    Mentorship: "", //One-on-One live courses Online Group courses Recorded courses
    LinkedIn: "",
    Website: "",
    Resume: "",
    previousExperience: "",
    additionalComments: "",
    estimatedCourseLength: "",
    syllabus: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form.name.trim().length < 3) {
      toast.error("Enter a valid full name", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (
      form.previousExperience.trim().length === 0 ||
      form.expertise.trim().length === 0 ||
      form.highestEducation.trim().length === 0
    ) {
      toast.error("Make sure to fill all the required fields", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      return;
    }
    try {
      const formData = {
        fullName: form.name,
        email: form.email,
        phoneNumber: form.phone,
        nationality: form.country,
        expertise: form.expertise,
        experience: form.experience,
        education: form.highestEducation,
        availability: form.Availability,
        mentorshipType: form.Mentorship,
        linkedinProfile: form.LinkedIn,
        website: form.Website,
        resume: form.Resume,
        previousExperience: form.previousExperience,
        additionalComments: form.additionalComments,
        syllabus: form.syllabus,
      };
      const { data } = await axios.post("/mentor", formData);
      toast.success(data.message);
      navigate("/");
      setForm({
        name: "",
        email: "",
        phone: "",
        country: "",
        expertise: "",
        courseName: "",
        experience: "",
        highestEducation: "",
        Availability: "", //full time Part-time Weekends only Evenings only
        Mentorship: "", //One-on-One live courses Online Group courses Recorded courses
        LinkedIn: "",
        Website: "",
        Resume: "",
        syllabus: "",
        References: "",
        previousExperience: "",
        additionalComments: "",
        estimatedCourseLength: "",
      });
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleResume = (e) => {
    const file = e.target.files[0];
    // Check if the selected file is an image or a PDF
    if (file && file.type === "application/pdf") {
      const reader = new FileReader();

      reader.onload = (event) => {
        // Convert the file to base64
        const base64Data = event.target.result;
        setForm((prevForm) => ({
          ...prevForm,
          Resume: base64Data,
        }));
      };

      reader.readAsDataURL(file);
    } else {
      toast.error("Please upload a PDF file");
    }
  };

  const handleSyllabus = (e) => {
    const file = e.target.files[0];
    // Check if the selected file is an image or a PDF
    if (file && file.type === "application/pdf") {
      const reader = new FileReader();

      reader.onload = (event) => {
        // Convert the file to base64
        const base64Data = event.target.result;
        setForm((prevForm) => ({
          ...prevForm,
          syllabus: base64Data,
        }));
      };

      reader.readAsDataURL(file);
    } else {
      toast.error("Please upload a PDF file");
    }
  };

  return (
    <>
      <Navbar />
      <ToastContainer />
      <div className={classes.contactSection}>
        {/* Title */}
        <div className={classes.contact}>
          <h3>Empower the Future: Join Our Mentorship Dream Team</h3>
        </div>
        <div className={`${classes.container} ${classes.contact__container}`}>
          <form onSubmit={handleSubmit}>
            <FormLabel
              required
              sx={{
                color: "white",
                zIndex: "-999",
                marginBottom: "2px !important",
                "&.Mui-focused": {
                  color: "white",
                },
              }}
            >
              Full Name
            </FormLabel>
            <input
              type="text"
              name="name"
              placeholder="Full Name"
              onChange={(e) =>
                setForm({ ...form, name: DOMPurify.sanitize(e.target.value) })
              }
              value={form.name}
              required
            />
            <FormLabel
              required
              sx={{
                zIndex: "-999",
                color: "white",
                marginBottom: "2px !important",
                "&.Mui-focused": {
                  color: "white",
                },
              }}
            >
              Email
            </FormLabel>
            <input
              type="email"
              name="email"
              onChange={(e) =>
                setForm({ ...form, email: DOMPurify.sanitize(e.target.value) })
              }
              value={form.email.trim()}
              placeholder="Email"
              required
            />
            <FormLabel
              required
              sx={{
                zIndex: "-999",
                color: "white",
                marginBottom: "2px !important",
                "&.Mui-focused": {
                  color: "white",
                },
              }}
            >
              Phone Number
            </FormLabel>
            <input
              type="text"
              name="phone"
              onChange={(e) => {
                const sanitizedValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
                const phoneNumber = sanitizedValue
                  ? parseInt(sanitizedValue, 10)
                  : "";
                setForm({ ...form, phone: phoneNumber });
              }}
              value={form.phone}
              placeholder="eg: 76144272"
              min={8}
              required
            />
            <FormLabel
              required
              sx={{
                color: "white",
                zIndex: "-999",
                marginBottom: "2px !important",
                "&.Mui-focused": {
                  color: "white",
                },
              }}
            >
              Expertise Area
            </FormLabel>
            <input
              type="text"
              name="expertise"
              placeholder="What is your domain of expertise?"
              value={form.expertise}
              onChange={(e) =>
                setForm({
                  ...form,
                  expertise: DOMPurify.sanitize(e.target.value),
                })
              }
              required
            />
            <FormLabel
              required
              sx={{
                zIndex: "-999",
                color: "white",
                marginBottom: "2px !important",
                "&.Mui-focused": {
                  color: "white",
                },
              }}
            >
              Course Name
            </FormLabel>
            <input
              type="text"
              name="courseName"
              placeholder="Course Name"
              value={form.courseName}
              onChange={(e) =>
                setForm({
                  ...form,
                  courseName: DOMPurify.sanitize(e.target.value),
                })
              }
              required
            />
            <FormLabel
              required
              sx={{
                zIndex: "-999",
                color: "white",
                marginBottom: "2px !important",
                "&.Mui-focused": {
                  color: "white",
                },
              }}
            >
              Education
            </FormLabel>
            <input
              type="text"
              name="highestEducation"
              placeholder="What is your highest educational degree?"
              value={form.highestEducation}
              onChange={(e) =>
                setForm({
                  ...form,
                  highestEducation: DOMPurify.sanitize(e.target.value),
                })
              }
              required
            />
            <FormLabel
              required
              sx={{
                zIndex: "-999",
                color: "white",
                marginBottom: "2px !important",
                "&.Mui-focused": {
                  color: "white",
                },
              }}
            >
              Your Country
            </FormLabel>
            <input
              type="text"
              name="country"
              placeholder="Country"
              value={form.country}
              onChange={(e) =>
                setForm({
                  ...form,
                  country: DOMPurify.sanitize(e.target.value),
                })
              }
              required
            />
            <FormControl fullWidth>
              <FormLabel
                required
                sx={{
                  zIndex: "-999",
                  color: "white",
                  marginBottom: "2px !important",
                  "&.Mui-focused": {
                    color: "white",
                  },
                }}
              >
                Experience Level
              </FormLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                required
                value={form.experience}
                onChange={(e) =>
                  setForm({
                    ...form,
                    experience: DOMPurify.sanitize(e.target.value),
                  })
                }
                sx={{
                  backgroundColor: "white",
                  marginBottom: "10px !important",
                  "&.Mui-focused": {
                    border: "none",
                  },
                  "&.Mui-hover": {
                    border: "none",
                  },
                }}
              >
                <MenuItem value="senior">Senior</MenuItem>
                <MenuItem value="lead">Lead</MenuItem>
                <MenuItem value="manager">Manager</MenuItem>
                <MenuItem value="founder">Founder</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <FormLabel
                required
                sx={{
                  zIndex: "-999",
                  color: "white",
                  marginBottom: "2px !important",
                  "&.Mui-focused": {
                    color: "white",
                  },
                }}
              >
                Availability
              </FormLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                required
                value={form.Availability}
                onChange={(e) =>
                  setForm({
                    ...form,
                    Availability: DOMPurify.sanitize(e.target.value),
                  })
                }
                sx={{
                  backgroundColor: "white",
                  marginBottom: "10px !important",
                  "&.Mui-focused": {
                    border: "none",
                  },
                  "&.Mui-hover": {
                    border: "none",
                  },
                }}
              >
                <MenuItem value="Full-time">Full time</MenuItem>
                <MenuItem value="Part-time">Part time</MenuItem>
                <MenuItem value="Weekends only">Weekends only</MenuItem>
                <MenuItem value="Evenings only">Evenings only</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <FormLabel
                required
                sx={{
                  zIndex: "-999",
                  color: "white",
                  marginBottom: "2px !important",
                  "&.Mui-focused": {
                    color: "white",
                  },
                }}
              >
                Your Preferred Mentoring Style?
              </FormLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                required
                value={form.Mentorship}
                onChange={(e) =>
                  setForm({
                    ...form,
                    Mentorship: DOMPurify.sanitize(e.target.value),
                  })
                }
                sx={{
                  backgroundColor: "white",
                  marginBottom: "10px !important",
                  "&.Mui-focused": {
                    border: "none",
                  },
                  "&.Mui-hover": {
                    border: "none",
                  },
                }}
              >
                <MenuItem value="One-on-One mentoring">One-on-One</MenuItem>
                <MenuItem value="Group mentoring">Group mentoring</MenuItem>
                <MenuItem value="Recorded courses">Recorded courses</MenuItem>
              </Select>
            </FormControl>
            <FormLabel
              sx={{
                color: "white",
                zIndex: "-999",
              }}
              required
            >
              Upload your Resume
            </FormLabel>
            <input
              type="file"
              name="resume"
              onChange={(e) => handleResume(e)}
              accept=".pdf"
              required
            />
            <FormLabel
              sx={{
                color: "white",
                zIndex: "-999",
              }}
              required
            >
              Upload the course syllabus
            </FormLabel>
            <input
              type="file"
              name="syllabus"
              onChange={(e) => handleSyllabus(e)}
              accept=".pdf"
              required
            />
            <FormLabel
              sx={{
                color: "white",
                zIndex: "-999",
              }}
              required
            >
              LinkedIn Profile Link
            </FormLabel>
            <input
              type="url"
              name="url"
              onChange={(e) =>
                setForm({
                  ...form,
                  LinkedIn: DOMPurify.sanitize(e.target.value),
                })
              }
              value={form.LinkedIn.trim()}
              placeholder="LinkedIn"
              required
            />
            <FormLabel
              sx={{
                color: "white",
                zIndex: "-999",
              }}
            >
              Your Website / Portfolio (optional)
            </FormLabel>
            <input
              type="url"
              name="url"
              onChange={(e) =>
                setForm({
                  ...form,
                  Website: DOMPurify.sanitize(e.target.value),
                })
              }
              value={form.Website.trim()}
              placeholder="Website"
            />

            <FormLabel
              sx={{
                color: "white",
                zIndex: "-999",
              }}
              required
            >
              Tell us about your previous experience
            </FormLabel>
            <textarea
              name="message"
              id=""
              rows="7"
              value={form.previousExperience}
              style={textareaStyles}
              onChange={(e) =>
                setForm({
                  ...form,
                  previousExperience: DOMPurify.sanitize(e.target.value),
                })
              }
              placeholder="Previous Experience Details"
              required
            ></textarea>
            <FormLabel
              sx={{
                color: "white",
                zIndex: "-999",
              }}
            >
              Do you have any additional comments?
            </FormLabel>
            <textarea
              type="text"
              name="additionalComments"
              rows="7"
              style={textareaStyles}
              onChange={(e) =>
                setForm({
                  ...form,
                  additionalComments: DOMPurify.sanitize(e.target.value),
                })
              }
              value={form.additionalComments}
              placeholder="Additional Comments(optional)"
            ></textarea>

            <button type="submit" className={classes.sendButton}>
              Submit Application
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Mentor;
