import React, { useEffect } from "react";
import NotFound1 from "../../assets/404.svg";
import "./NotFound.css";
import { Link } from "react-router-dom";

const NotFound = () => {


  useEffect(() => {
    console.clear();
  },[])

  return (
    <>
      <div class="stars">
        <div class="central-body">
{/*           <img class="image-404" src={NotFound1} alt="notFound" width="300px" /> */}
          <h1 style={{"color":"white","fontSize":"3rem"}}>Invoice Already Submitted</h1>
          <Link to="/">
            <a href="/" class="btn-go-home">
              Go Back Home
            </a>
          </Link>
        </div>
        {/* <div class="objects">
          <img
            class="object_rocket"
            src="http://salehriaz.com/404Page/img/rocket.svg"
            width="40px"
          />
          <div class="earth-moon">
            <img
              class="object_earth"
              src="http://salehriaz.com/404Page/img/earth.svg"
              width="100px"
            />
            <img
              class="object_moon"
              src="http://salehriaz.com/404Page/img/moon.svg"
              width="80px"
            />
          </div>
          <div class="box_astronaut">
            <img
              class="object_astronaut"
              src="http://salehriaz.com/404Page/img/astronaut.svg"
              width="140px"
            />
          </div>
        </div> */}
        <div class="glowing_stars">
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
