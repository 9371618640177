import React from "react";
import ReactDOM from "react-dom";
import "./globalStyles/index.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";

// Disable React development warnings
console.warn = console.error = () => {};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
