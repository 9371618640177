import React from "react";
import classes from "./review.module.css";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";

const Review = ({ imageUrl, name, rating, text }) => {
  return (
    <>
      <div className={classes.review}>
        <div className={classes.image}>
          <img src={imageUrl} alt={name} />
        </div>
        <div className={classes.infoReview}>
          <div className={classes.nameOfReviewer}>
            <div className={classes.name}>
              <h3>{name}</h3>
            </div>
            <div className={classes.rating}>
              <Rating
                value={rating}
                precision={0.5}
                size="20"
                readOnly
                emptyIcon={
                  <StarIcon
                    style={{ opacity: 0.55, color: "white" }}
                    fontSize="inherit"
                  />
                }
              />
            </div>
          </div>
          <div className={classes.reviewText}>{text}</div>
        </div>
      </div>
      <hr />
    </>
  );
};

export default Review;
