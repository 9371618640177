import React from "react";
import classes from "../../screens/Home/home.module.css";
import Skeleton from "react-loading-skeleton";

const Skeleton1 = ({ jobsPerPage }) => {
  return (
    <div style={{ height: "600px" }}>
      {Array.from({ length: jobsPerPage }).map((_, index) => (
        <div key={index} className={classes.skeletonCard}>
          <Skeleton width={300} height={200} style={{ marginBottom: "20px" }} />
          <Skeleton count={2} style={{ marginBottom: "10px" }} />
        </div>
      ))}
    </div>
  );
};

export default Skeleton1;
