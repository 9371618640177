import React, { useState, useEffect } from "react";
import "./navbar.css";
import Logo from "../../assets/white_logo.png";
import { Link } from "react-router-dom";

const links = [
  { name: "About Us", href: "/about-us" },
  { name: "Courses", href: "/" },
  { name: "Contact Us", href: "/contact-us" },
  { name: "Our Champions", href: "/techlarious-champions" },
  { name: "Become a Mentor", href: "/mentor" },
];

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector(".navbar1");
      const scrolled = window.scrollY;

      if (scrolled > 0) {
        navbar.classList.add("scrolled");
      } else {
        navbar.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <nav className="navbar1">
      <div className="container1">
        <div
          style={{
            cursor: "pointer",
          }}
        >
          <Link to="/">
            <img src={Logo} height={60} width={60} alt="techlarious" />
          </Link>
        </div>
        <div className="SideBarMenu__close menu-icon">
          <input
            onChange={(e) => handleShowNavbar(e)}
            type="checkbox"
            id="checkbox"
            value={showNavbar}
          />
          <label htmlFor="checkbox" className="toggle">
            <div className="bars" id="bar1"></div>
            <div className="bars" id="bar2"></div>
            <div className="bars" id="bar3"></div>
          </label>
        </div>
        <div className={`nav-elements  ${showNavbar && "active"}`}>
          <ul>
            {links.map((link, i) => {
              const isActive = link.href === window.location.pathname;
              return (
                <li
                  className={isActive ? "activeLink" : ""}
                  key={i}
                  onClick={() => setShowNavbar(false)}
                >
                  <Link to={link.href} style={{ color: "white" }}>
                    {link.name}
                  </Link>
                </li>
              );
            })}
            {showNavbar && (
              <li onClick={() => setShowNavbar(false)}>
                <a
                  style={{
                    color: "white",
                  }}
                  href={"https://api.whatsapp.com/send?phone=76144272"}
                >
                  Whatsapp Us
                </a>
              </li>
            )}
          </ul>
        </div>
        <div className="buttons">
          <button
            className="login"
            style={{
              fontSize: "17px",
              textDecoration: "none",
              fontWeight: "600",
              position: "relative",
              color: "white",
            }}
          >
            <a href="https://api.whatsapp.com/send?phone=76144272">
              Whatsapp Us
            </a>
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
