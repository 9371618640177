import React, { useState, useEffect } from "react";
import classes from "./home.module.css";
import Card from "../../components/card/Card";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import axios from "../../axios/axios";
import { toast } from "react-toastify";
import SkeletonLoading from "../../components/skeleton/Skeleton";
import { motion } from "framer-motion";

const Home = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [cards, setCards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const jobsPerPage = 3;

  const cardVariants = {
    hidden: { x: -100, opacity: 0 },
    visible: { x: 0, opacity: 1 },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/courses");
        setCards(response.data);
      } catch (error) {
        toast.error(error.response?.data?.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const totalPages = Math.ceil(cards?.length / jobsPerPage);
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );

  const handleClick = (pageNumber) => {
    window.scrollTo(0, 0);
    setCurrentPage(pageNumber);
  };

  const noCourses = cards.length === 0 && !isLoading;

  return (
    <>
      <Navbar />
      <div className={classes.courseSection}>
        <h2>Available courses</h2>
        <div
          style={{ marginTop: "50px" }}
          className={noCourses ? classes.noCourses1 : classes.cardContainer}
        >
          {isLoading ? (
            <>
              <SkeletonLoading jobsPerPage={jobsPerPage} />
              <SkeletonLoading jobsPerPage={jobsPerPage} />
              <SkeletonLoading jobsPerPage={jobsPerPage} />
            </>
          ) : (
            cards
              .slice((currentPage - 1) * jobsPerPage, currentPage * jobsPerPage)
              .map((card) => (
                <Link key={card._id} to={`/course/${card.courseSlug}`}>
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={cardVariants}
                    transition={{ duration: 0.9 }}
                  >
                    <Card course={card} />
                  </motion.div>
                </Link>
              ))
          )}
          {cards.length === 0 && !isLoading && (
            <div className={classes.noCourses}>
              <h1>No Courses yet!</h1>
            </div>
          )}
        </div>
        {!isLoading && cards?.length > jobsPerPage && (
          <div className={classes.pagination}>
            <div className="mt-3">
              <div className={`${classes.customPagination}`}>
                <button
                  className={classes.prev}
                  onClick={(e) => {
                    e.preventDefault();
                    if (currentPage === 1) return;
                    handleClick(currentPage - 1);
                  }}
                  disabled={currentPage === 1}
                >
                  Prev
                </button>
                <div className={classes.pages}>
                  {pageNumbers.map((pageNumber) => (
                    <button
                      key={pageNumber}
                      className={
                        pageNumber === currentPage
                          ? `${classes.white} ${classes.active}`
                          : `${classes.white}`
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        handleClick(pageNumber);
                      }}
                    >
                      {pageNumber}
                    </button>
                  ))}
                </div>
                <button
                  className={classes.next}
                  onClick={(e) => {
                    e.preventDefault();
                    if (currentPage === totalPages) return;
                    handleClick(currentPage + 1);
                  }}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        )}
        {!isLoading && <div style={{ height: "100px" }}></div>}
      </div>
      {!isLoading && <Footer />}
    </>
  );
};

export default Home;
