import React from "react";
import "./UserInfo.css";
const UserInfo = ({ name, imageSrc, field, batch }) => {
  return (
    <div className="card-container">
      <div className="image-container">
        <img src={imageSrc} alt="" />
      </div>
      <div className="personal-info">
        <h4>{name}</h4>
        <p>{field}</p>
        <p>{batch}</p>
      </div>
    </div>
  );
};

export default UserInfo;
