import React from "react";
import classes from "./instructor.module.css";
import { FaTwitter, FaLinkedin, FaGithub, FaInstagram } from "react-icons/fa";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

const Instructor = ({
  imageUrl,
  instructorName,
  field,
  twitterUrl,
  github,
  instaUrl,
  totalStudents,
  totalReviews,
  linkedUrl,
  instructorDescription,
  course,
}) => {
  return (
    <>
      <div className={classes.instructorContainer}>
        <div className={classes.leftContainer}>
          <div className={classes.header}>
            <h3>Instructor</h3>
          </div>
          <div className={classes.name}>
            <h2>{instructorName}</h2>
          </div>
          <div className={classes.field}>
            <h3>{field}</h3>
          </div>
          <div className={classes.info}>
            <div>
              <h2>Total Students</h2>
              <h4>{totalStudents}</h4>
            </div>
            <div>
              <h2>Reviews</h2>
              <h4>{totalReviews}</h4>
            </div>
          </div>
          <div className={classes.infoDescription}>
            <div>
              <h2>About Instructor</h2>
            </div>
            <div className={classes.desc}>
              <p>{instructorDescription}</p>
            </div>
          </div>
        </div>
        <div className={classes.rightContainer}>
          <div className={classes.image}>
            <img src={imageUrl} alt={instructorName} />
          </div>
          <div className={classes.links}>
            <a href={twitterUrl} target="_blank" rel="noreferrer">
              <FaTwitter color="white" />
            </a>
            <a href={github} target="_blank" rel="noreferrer">
              <FaGithub color="white" />
            </a>
            <a href={instaUrl} target="_blank" rel="noreferrer">
              <FaInstagram color="white" />
            </a>
            <a href={linkedUrl} target="_blank" rel="noreferrer">
              <FaLinkedin color="white" />
            </a>
          </div>
        </div>
      </div>
      <div className={classes.listingTopics}>
        <h2 className={classes.header}>Related Courses</h2>
        {/* TOPICS */}
        {course.map((topic, i) => (
          <div key={i} className={classes.singleLearn}>
            <div>
              <BsFillArrowRightCircleFill
                color="white"
                size={24}
                style={{
                  verticalAlign: "middle",
                  backgroundColor: "#1B4481",
                  borderRadius: "50%",
                  border: "none",
                }}
              />{" "}
            </div>
            <div className={classes.topic}>
              <p>{topic?.courseName}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Instructor;
