import React from "react";
import classes from "./card.module.css";
import { FaCalendar } from "react-icons/fa";
import { BsAlarm } from "react-icons/bs";

function transformDate(dateString) {
  const date = new Date(dateString);
  
  const day = date.getDate();
  const month = date.getMonth() + 1; 
  const year = date.getFullYear();
  
  return `${day}/${month}/${year}`;
}

const Card = ({ course }) => {
  
  return (
    <div className={classes.card}>
      {/* Image */}
      <div className={`${classes.image}`}>
        <img src={course?.courseImage} alt="img" />
      </div>
      {/* Information */}
      <div className={` ${classes.info}`}>
        {/* Title */}
        <div className={classes.title}>
          <h3>{course.courseName}</h3>
          <div className={classes.level}>{course.courseDifficulty}</div>
        </div>
        <div className={classes.desc}>
          <h5>{course.courseTagline} </h5>
        </div>
      </div>
      {/* Duration */}
      <div className={classes.cardTime}>
        <div className={classes.cardDate}>
          <div
            style={{
              marginBottom: "10px",
            }}
          >
            <FaCalendar color="white" />
          </div>
          <div>
            <p>
              {course.startingDate}
            </p>
          </div>
        </div>
        <div className={classes.cardDuration}>
          <div
            style={{
              marginBottom: "10px",
            }}
          >
            <BsAlarm color="white" />
          </div>
          <div>
            <p>{course.nbOfWeeks} {course.scale}</p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          backgroundColor: "white",
          height: "1px",
        }}
      />
      {/* Author */}
      <div className={classes.Author}>
        <div className={classes.AuthorImage}>
          <div>
            <img
              src={course?.mentor?.profilePicture}
              alt="img"
              style={{
                width: "47px",
                height: "45px",
                borderRadius: "50%",
              }}
            />
          </div>
          <div className={classes.AuthorText}>
            <h4>{course?.mentor?.fullName}</h4>
          </div>
        </div>
        <div className={classes.price}>
          <p className={classes.price1}>
            {course?.courseDiscount
              ? course?.coursePrice - course?.courseDiscount === 0
                ? ""
                : course?.coursePrice - course?.courseDiscount + "$"
              : course?.coursePrice + "$"}{" "}
          </p>
          {course?.courseDiscount !== 0 &&
            course?.courseDiscount !== course?.coursePrice && (
              <>
                <p className={classes.oldPrice}>{course?.coursePrice} $</p>
                <p className={classes.discount}>
                  {course?.courseDiscount}$ off
                </p>
              </>
            )}
          {course?.courseDiscount !== 0 &&
            course?.courseDiscount == course?.coursePrice && (
              <p className={classes.Free}>Free</p>
            )}
        </div>
      </div>
      <div
        className={classes.appear}
        style={{
          width: "90%",
          backgroundColor: "white",
          height: "1px",
        }}
      />
      {/* Button */}
      <div
        className={classes.appear}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <button className={classes.submit}>Enroll Now</button>
      </div>
    </div>
  );
};

export default Card;
