import React, { useEffect, useState } from "react";
import classes from "./finanialAid.module.css";
import { FormControl, FormLabel, MenuItem, Select } from "@mui/material";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import axios from "../../axios/axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";

const Financial = () => {
  const textareaStyles = {
    resize: "none",
  };
  const [course, setCourse] = useState("");
  const { id } = useParams();

  const navigate = useNavigate();

  const [form, setForm] = useState({
    course: course?.courseName,
    name: "",
    email: "",
    phone: "",
    currentStatus: "",
    numOfInstallments: "",
    message: "",
  });

  useEffect(() => {
    console.clear();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form.phone.toString().length < 8) {
      toast.error("Enter a valid phone number", {
        position: "top-center",
        autoClose: 2000,
      });
      return;
    }
    // Email validation regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(form.email.trim())) {
      toast.error("Enter a valid email address", {
        position: "top-center",
        autoClose: 2000,
      });
      return;
    }
    if (form.name.trim().length < 3) {
      toast.error("Full Name is required", {
        position: "top-center",
        autoClose: 2000,
      });
      return;
    }
    if (form.message.trim().length < 3) {
      toast.error("Enter a valid message", {
        position: "top-center",
        autoClose: 2000,
      });
      return;
    }
    try {
      const formData = {
        course: course?._id,
        name: form.name.trim(),
        email: form.email.trim(),
        phone: form.phone,
        currentStatus: form.currentStatus.trim(),
        numOfInstallments: form.numOfInstallments.trim(),
        reasonOfInterest: form.message.trim(),
      };
      const { data } = await axios.post(`/financial-aid/apply`, formData);
      setForm({
        name: "",
        email: "",
        phone: "",
        currentStatus: "",
        numOfInstallments: "",
        reasonOfInterest: "",
      });
      toast.success(data.message);
      navigate("/");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const { data } = await axios.get(`/courses/${id}`);
        setCourse(data);
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    fetchCourse();
    window.scroll(0, 0);
  }, [id]);
  return (
    <>
      <Navbar />
      <div className={classes.contactSection}>
        {/* Title */}
        <div className={classes.contact}>
          <h3>
            Pay Your Way: Divide Course Fees into Convenient Installments!
          </h3>
        </div>
        <div className={`${classes.container} ${classes.contact__container}`}>
          <form onSubmit={handleSubmit}>
            <FormLabel
              required
              sx={{
                color: "white",
                zIndex: -99,
              }}
            >
              Course
            </FormLabel>
            <input
              type="text"
              name="name"
              value={course?.courseName}
              readOnly
              required
            />
            <FormLabel
              required
              sx={{
                color: "white",
                zIndex: -99,
              }}
            >
              Name
            </FormLabel>
            <input
              type="text"
              name="name"
              placeholder="Full Name"
              onChange={(e) =>
                setForm({ ...form, name: DOMPurify.sanitize(e.target.value) })
              }
              value={form.name}
              required
            />
            <FormLabel
              required
              sx={{
                color: "white",
                zIndex: -99,
              }}
            >
              Email
            </FormLabel>
            <input
              type="email"
              name="email"
              onChange={(e) =>
                setForm({ ...form, email: DOMPurify.sanitize(e.target.value) })
              }
              value={form.email.trim()}
              placeholder="Email"
              required
            />
            <FormLabel
              required
              sx={{
                color: "white",
                zIndex: -99,
              }}
            >
              Phone Number
            </FormLabel>
            <input
              type="text"
              name="phone"
              onChange={(e) => {
                const sanitizedValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
                const phoneNumber = sanitizedValue
                  ? parseInt(sanitizedValue, 10)
                  : "";
                setForm({ ...form, phone: phoneNumber });
              }}
              value={form.phone}
              placeholder="eg: 76144272"
              min={8}
              required
            />
            <FormControl fullWidth>
              <FormLabel
                required
                sx={{
                  color: "white",
                  marginBottom: "10px !important",
                  "&.Mui-focused": {
                    color: "white",
                  },
                }}
              >
                Your current status
              </FormLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                required
                value={form.currentStatus}
                onChange={(e) =>
                  setForm({
                    ...form,
                    currentStatus: DOMPurify.sanitize(e.target.value),
                  })
                }
                sx={{
                  backgroundColor: "white",
                  marginBottom: "10px !important",
                }}
              >
                <MenuItem value="Student">Student</MenuItem>
                <MenuItem value="Full Time">Employee (Full Time)</MenuItem>
                <MenuItem value="Part Time">Employee (Part Time)</MenuItem>
                <MenuItem value="Intern">Intern</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <FormLabel
                required
                sx={{
                  color: "white",
                  marginBottom: "10px !important",
                  "&.Mui-focused": {
                    color: "white",
                  },
                }}
              >
                How many installments would you like to choose for paying the
                course fees?
              </FormLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                required
                value={form.numOfInstallments}
                onChange={(e) =>
                  setForm({
                    ...form,
                    numOfInstallments: DOMPurify.sanitize(e.target.value),
                  })
                }
                sx={{
                  backgroundColor: "white",
                  marginBottom: "10px !important",
                }}
              >
                <MenuItem value={2}>2 installments</MenuItem>
                <MenuItem value={3}>3 installments</MenuItem>
              </Select>
            </FormControl>

            <FormLabel
              required
              sx={{
                color: "white",
                marginBottom: "10px !important",
              }}
            >
              Why you are interested in taking this course?
            </FormLabel>
            <textarea
              name="message"
              id=""
              rows="7"
              style={textareaStyles}
              value={form.message}
              onChange={(e) =>
                setForm({
                  ...form,
                  message: DOMPurify.sanitize(e.target.value),
                })
              }
              placeholder="Why you are interested in taking this course?"
              required
            ></textarea>
            <br />

            <button type="submit" className={classes.sendButton}>
              Submit your financial aid
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Financial;
