import React, { useEffect, useState } from "react";
import classes from "./singleCourse.module.css";
import { CgDanger } from "react-icons/cg";
import { SiOpslevel } from "react-icons/si";
import { GiEarthAmerica } from "react-icons/gi";
import BasicTabs from "../../components/Tabs/Tabs";
import InnerCard from "../../components/InnerCard/InnerCard";
import CourseRevenue from "../../components/CourseRevenue/CourseRevenue";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { useParams, useNavigate } from "react-router-dom";
import axios from "../../axios/axios";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";

const SingleCourse = () => {
  const navigate = useNavigate();
  const [isWideScreen, setIsWideScreen] = useState(false);
  const [course, setCourse] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 658);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.clear();
    const getSingleProduct = async () => {
      try {
        const { data } = await axios.get(`/courses/${id}`);
        setCourse(data);
      } catch (error) {
        navigate("/404");
        toast.error(error.response.data.message);
      } finally {
        setIsLoading(false);
      }
    };

    getSingleProduct();
    window.scroll(0, 0);
  }, [id]);

  return (
    <>
      <Navbar />

      {isLoading ? (
        <div
          className={classes.skeletonCard}
          style={{
            marginTop: "150px",
          }}
        >
          <Skeleton width={300} height={200} style={{ marginTop: "20px" }} />
          <Skeleton count={2} style={{ marginBottom: "10px" }} />
        </div>
      ) : (
        <div className={classes.courseIntro}>
          <h1>{course?.courseName}</h1>
          <p>{course?.courseSummary}</p>
          <div className={classes.points}>
            <div className={classes.point}>
              <div className={classes.pointIcon}>
                <SiOpslevel />
              </div>
              <div className={classes.pointText}>
                {course?.courseDifficulty}
              </div>
            </div>
            <div className={classes.point}>
              <div className={classes.pointIcon1}>
                <CgDanger />
              </div>
              <div className={classes.pointText}>Limited seats</div>
            </div>
            <div className={classes.point}>
              <div className={classes.pointIcon2}>
                <GiEarthAmerica color="white" />
              </div>
              <div className={classes.pointText}>
                {course?.courseLanguages.map((c) => (
                  <p key={c}>{c}</p>
                ))}
              </div>
            </div>
            <div className={classes.point}>
              <div className={classes.star}>
                {
                  course?.courseRating !== 0 &&   (
                    <>
                    <Rating
                  value={course?.courseRating}
                  precision={0.1}
                  sx={{ zIndex: 1 }}
                  readOnly
                  emptyIcon={
                    <StarIcon
                      style={{ opacity: 0.2, color: "white" }}
                      fontSize="inherit"
                    />
                  }
                />
                  <p>
                  ({course?.courseRating > 5 ? 5 : course?.courseRating} / 5)
                </p></>)
                }
               
                
              </div>
            </div>
          </div>
        </div>
      )}

      {!isWideScreen ? (
        <div className={classes.lowerContainer}>
          <div className={classes.card1}>
            {isLoading ? (
              <div className={classes.skeletonCard}>
                <Skeleton width={300} height={200} />
                <Skeleton count={2} style={{ marginBottom: "10px" }} />
              </div>
            ) : (
              <InnerCard course={course} />
            )}
          </div>
          <div className={classes.card2}>
            {isLoading ? (
              <div className={classes.skeletonCard}>
                <Skeleton
                  width={300}
                  height={200}
                  style={{ marginBottom: "20px" }}
                />
                <Skeleton count={2} style={{ marginBottom: "10px" }} />
              </div>
            ) : (
              <BasicTabs course={course} />
            )}
          </div>
          <div className={classes.card3}>
            {isLoading ? (
              <div className={classes.skeletonCard}>
                <Skeleton
                  width={300}
                  height={200}
                  style={{ marginBottom: "20px" }}
                />
                <Skeleton count={2} style={{ marginBottom: "10px" }} />
              </div>
            ) : (
              <CourseRevenue course={course} />
            )}
          </div>
        </div>
      ) : (
        <div className={classes.lowerContainer}>
          <div className={classes.Tabs}>
            {isLoading ? (
              <div className={classes.skeletonCard}>
                <Skeleton
                  width={300}
                  height={800}
                  style={{ marginBottom: "20px" }}
                />
                <Skeleton count={2} style={{ marginBottom: "10px" }} />
              </div>
            ) : (
              <BasicTabs course={course} />
            )}
          </div>
          <div className={classes.cards}>
            <div>
              {isLoading ? (
                <div className={classes.skeletonCard}>
                  <Skeleton
                    width={300}
                    height={200}
                    style={{ marginBottom: "20px" }}
                  />
                  <Skeleton count={2} style={{ marginBottom: "10px" }} />
                </div>
              ) : (
                <InnerCard course={course} />
              )}
            </div>
            <div className={classes.course1}>
              {isLoading ? (
                <div className={classes.skeletonCard}>
                  <Skeleton
                    width={300}
                    height={200}
                    style={{ marginBottom: "20px" }}
                  />
                  <Skeleton count={2} style={{ marginBottom: "10px" }} />
                </div>
              ) : (
                <CourseRevenue course={course} />
              )}
            </div>
          </div>
        </div>
      )}

      {!isLoading && <Footer />}
    </>
  );
};

export default SingleCourse;
