import React, { useEffect } from "react";
import classes from "./About.module.css";
import Team from "./team/Team";
import Logo1 from "../../assets/Logo2.png";
import Badge from "./badges/Badge";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const teamMembers = [
  {
    id: 1,
    avatar: "https://i.ibb.co/9WQ9p6H/nis.jpg",
    name: "Nisrine Bou Ghannam",
    position: "CEO & Founder",
    description:
      "Innovator extraordinaire, shaping the future of self-technological learning with fearless creativity and unwavering determination.",
    socialMedia: [
      { icon: "instagram", link: "https://instagram.com/nisrine_aboughannam" },
      { icon: "linkedin", link: "https://www.linkedin.com/in/nisrine-abou-ghannam/" },
      { icon: "whatsapp", link: "https://wa.me/message/PJWWEXU2OP4LA1"}
    ],
  },
  {
    id: 2,
    avatar: "https://i.ibb.co/VLy57HT/elieh-photo.jpg",
    name: "Elie Hannouch",
    position: "Engineering Manager",
    description:
      "Mastermind behind groundbreaking ideas, driving technological advancements with a relentless pursuit of excellence.",
    socialMedia: [
      { icon: "instagram", link: "https://instagram.com/elie_hannouch" },
      { icon: "linkedin", link: "https://www.linkedin.com/in/elie-hannouch" },
      { icon: "whatsapp", link: "https://wa.me/message/SDLXPKKWZCTBC1"}

    ],
  },

  
];

const About = () => {
  useEffect(() => {
    console.clear();
  },[])
  return (
    <>
      <Navbar />
      <div className={classes.aboutContainer}>
        <h3>
          <span>About Us</span>
        </h3>
        <div className={classes.about}>
          <div className={classes.container}>
            <div className={classes.aboutDescription}>
              <p>
                Welcome to TECHlarious, the Lebanese tech company that takes
                education to the  next  level! <br /> With our expertise in the
                ever-evolving world of technology, we specialize in  providing
                enthusiasts with an exhilarating range of tech and management
                courses. Our mission is to empower you with the knowledge and
                skills to excel in the digital era.
              </p>
              <p>
                Stay ahead of the game as we guide you through the latest
                advancements, ensuring you're fully equipped to conquer the
                market with confidence. Our dynamic curriculum is meticulously
                designed to cover the most cutting-edge technologies, giving you
                a competitive edge in the industry. Whether you're a tech
                aficionado or a budding entrepreneur, TECHlarious is your
                trusted partner on the path to success.
              </p>
            </div>
            <div className={classes.Logo}>
              <img src={Logo1} alt="Tomo" />
            </div>
          </div>
        </div>
      </div>
      <Badge />
      <div className={classes.teamSection}>
        <div className={classes.teamTitle}>
          <h1>Team Members</h1>
        </div>
        <div className={classes.teamContainer}>
        {teamMembers.map((teamMember) => (
        <Team
          key={teamMember.id}
          avatar={teamMember.avatar}
          name={teamMember.name}
          position={teamMember.position}
          description={teamMember.description}
          socialMedia={teamMember.socialMedia}
        />
      ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
