import React, { useState } from "react";
import Logo from "../../assets/white_logo.png";
import { Link } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import { BsFillTelephoneOutboundFill } from "react-icons/bs";
import { TiMessages } from "react-icons/ti";
import classes from "./Footer.module.css";
import { toast } from "react-toastify";
import axios from "../../axios/axios";

const Footer = () => {
  function handleClick() {
    const element = document.documentElement || document.body;
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }
  const [email, setEmail] = useState("");
  const handleSubscribe = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post("/subscription/subscribe", { email });
      toast.success(data.message);
      setEmail("");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.firstContainer}>
          <h1 className={classes.footerTitle}>
            TECHlarious{" "}
            <img src={Logo} width={35} height={35} alt="Techlarious" />
          </h1>
          <p className={classes.footerParagraph}>
            Embrace success in the digital era with TECHlarious! Our
            exhilarating tech and management courses empower you with
            cutting-edge knowledge and skills. Stay ahead of the game, gain a
            competitive edge, and conquer the market with confidence. Let
            TECHlarious be your trusted partner on the path to success!
          </p>
        </div>

        <div className={classes.footerPages}>
          <h5 className={classes.footerPagesTitle}>Pages</h5>
          <ul className={classes.pageList}>
            <li className={classes.item}>
              <Link to="/" onClick={handleClick}>
                <p> Our Courses</p>
              </Link>
            </li>
            <li className={classes.item}>
              <Link to="/contact-us" onClick={handleClick}>
                <p>Contact Us</p>
              </Link>
            </li>
            <li className={classes.item}>
              <Link to="/about-us" onClick={handleClick}>
                <p>About Us</p>
              </Link>
            </li>
          </ul>
        </div>

        <div className={classes.footerContacts}>
          <h6 className={classes.contactTitle}>Contact</h6>
          <div className={classes.contact}>
            <AiFillHome className="mr-1" fontSize={25} />{" "}
            <p className={classes.contactInfo}>Beirut, Lebanon</p>
          </div>
          <div className={classes.contact}>
            <TiMessages className="mr-1" fontSize={22} />{" "}
            <p className={classes.contactInfo}>
              <a
                href="mailto:info@techlarious.com"
                style={{
                  textDecoration: "none",
                  color: "white",
                }}
              >
                {" "}
                info@techlarious.com
              </a>
            </p>
          </div>
          <div className={classes.contact}>
            <BsFillTelephoneOutboundFill className="mr-1" fontSize={20} />{" "}
            <p className={classes.contactInfo}>
              <a
                href="tel:+96176144272"
                style={{
                  textDecoration: "none",
                  color: "white",
                }}
              >
                76 144 272
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className={classes.footerLastSection}>
        <div className={classes.footerEnd}>
          {" "}
          &copy; {new Date().getFullYear()} TECHlarious & Two Of Us
        </div>
        <div className={classes.newsLetter}>
          <form
            onSubmit={handleSubscribe}
            className={classes.input}
            autoComplete="off"
          >
            <input
              type="email"
              placeholder="Email Address"
              onChange={(e) => setEmail(e.target.value)}
              required
              value={email}
            />
            <button type="submit" className={classes.submit}>
              Subsribe
            </button>
          </form>
        </div>
        <div className={classes.icons}>
          <a
            className={classes.icon}
            href="https://youtube.com/@techlarioushub"
            role="button"
            data-mdb-ripple-color="dark"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-youtube"></i>
          </a>

          <a
            className={classes.icon}
            href="https://www.instagram.com/techlarioushub/"
            role="button"
            data-mdb-ripple-color="dark"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-instagram"></i>
          </a>

          <a
            className={classes.icon}
            href="https://www.linkedin.com/company/techlarious"
            role="button"
            data-mdb-ripple-color="dark"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-linkedin"></i>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
