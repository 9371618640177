import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./screens/Home/Home";
import Champions from "./screens/champions/Champions";
import SingleCourse from "./screens/SingleCourse/SingleCourse";
import Contact from "./components/contact/Contact.jsx";
import About from "./screens/about/About";
import Financial from "./screens/financialAid/FinanialAid.jsx";
import Fees from "./components/collectFees/CollectFees.jsx";
import NotFound from "./screens/notFound/NotFound";
import { ToastContainer } from "react-toastify";
import Mentor from "./screens/mentorship/MentorShip";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/financial-aid/:id" element={<Financial />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/course/:id" element={<SingleCourse />} />
        <Route path="/submit-invoice/:id" element={<Fees />} />
        <Route path="/techlarious-champions" element={<Champions />} />
        <Route path="/Mentor" element={<Mentor />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;
