import React, { useState } from "react";
import classes from "./contact.module.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import axios from "../../axios/axios";
import { toast, ToastContainer } from "react-toastify";
import DOMPurify from "dompurify";

const Contact = () => {
  const textareaStyles = {
    resize: "none",
  };

  const [form, setForm] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form.name.trim().length < 3) {
      toast.error("Enter a valid full name", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (form.subject.trim().length === 0 || form.message.trim().length === 0) {
      toast.error("Make sure to fill all the required fields", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      return;
    }
    try {
      const formData = {
        fullName: form.name,
        subject: form.subject,
        email: form.email,
        message: form.message,
      };
      const { data } = await axios.post("/communication/contact-us", formData);
      toast.success(data.message);
      setForm({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  return (
    <>
      <Navbar />
      <ToastContainer />
      <div className={classes.contactSection}>
        {/* Title */}
        <div className={classes.contact}>
          <h3>Need Answers ? We're Just a Click Away!</h3>
        </div>
        <div className={`${classes.container} ${classes.contact__container}`}>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Full Name"
              onChange={(e) =>
                setForm({ ...form, name: DOMPurify.sanitize(e.target.value) })
              }
              value={form.name}
              required
            />
            <input
              type="email"
              name="email"
              onChange={(e) =>
                setForm({ ...form, email: DOMPurify.sanitize(e.target.value) })
              }
              value={form.email.trim()}
              placeholder="Your Email"
              required
            />
            <input
              type="text"
              name="subject"
              placeholder="Your Subject here"
              value={form.subject}
              onChange={(e) =>
                setForm({
                  ...form,
                  subject: DOMPurify.sanitize(e.target.value),
                })
              }
              required
            />
            <textarea
              name="message"
              id=""
              rows="7"
              value={form.message}
              style={textareaStyles}
              onChange={(e) =>
                setForm({
                  ...form,
                  message: DOMPurify.sanitize(e.target.value),
                })
              }
              placeholder="Your Message"
            ></textarea>
            <br />
            <button type="submit" className={classes.sendButton}>
              Send Message
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
