import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import classes from "./faqs.module.css";

const Section = ({ element }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Accordion
        expanded={expanded}
        onChange={handleAccordionChange}
        sx={{
          marginBottom: "20px",
          marginTop: "20px",
          backgroundColor: "#18396c",
          color: "white",
        }}
      >
        <AccordionSummary
          expandIcon={
            <span
              style={{
                transition: "transform 0.3s",
              }}
            >
              {expanded ? (
                <FaChevronUp color="white" />
              ) : (
                <FaChevronDown color="white" />
              )}
            </span>
          }
        >
          <p className={classes.title}>{element.question}</p>
        </AccordionSummary>
        <AccordionDetails>
          <p className={classes.answer}>{element.answer}</p>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default Section;
