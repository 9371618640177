import React from "react";
import classes from "./badge.module.css";
import Arrow from "../../../assets/arrow.png";
import Student from "../../../assets/icons8-students-64.png";
import Course from "../../../assets/icons8-course-50.png";
import Teacher from "../../../assets/icons8-teacher-50.png";

const Badge = () => {
  return (
    <>
      <div className={classes.container}>
        <div className={classes.title}>
          <h1>
            Shaping the future
            <br /> One technologist at a time
          </h1>
        </div>
        <div className={classes.badges}>
          <div className={classes.badge}>
            <h1>18+</h1>
            <h2>
              {" "}
              <img src={Course} alt="Course" />
              Batches
            </h2>
          </div>
          <div className={classes.arrow}>
            <img src={Arrow} alt="arrow" width={165} />
          </div>
          <div className={classes.badge}>
            <h1>450+ </h1>
            <h2>
              {" "}
              <img src={Student} alt="Student" />
              Students
            </h2>
          </div>
          <div className={classes.arrow}>
            <img src={Arrow} alt="arrow" width={165} />
          </div>
          <div className={classes.badge}>
            <h1>3+ </h1>
            <h2>
              {" "}
              <img src={Teacher} alt="Teacher" />
              Mentors
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default Badge;
