import React, { useEffect, useState } from "react";
import "./champions.css";
import UserInfo from "../../components/champions/UserInfo";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import axios from "../../axios/axios";
import SkeletonLoading from "../../components/skeleton/Skeleton";
import { motion } from "framer-motion";

function Champions() {
  const [champions, setChampions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 12;
  const totalPages = Math.ceil(champions?.length / jobsPerPage);
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );
  const handleClick = (pageNumber) => {
    window.scrollTo(0, 0);
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const fetchChampions = async () => {
      try {
        const { data } = await axios.get("/champions/techlarious-champions");
        setChampions(data.data);
      } catch (error) {
      } finally {
        setIsLoading(false); // Set isLoading to false after API call
      }
    };
    fetchChampions();
  }, []);
  const cardVariants = {
    hidden: { x: -100, opacity: 0 },
    visible: { x: 0, opacity: 1 },
  };

  return (
    <>
      <Navbar />
      {isLoading ? (
        <>
          <SkeletonLoading jobsPerPage={jobsPerPage} />
        </>
      ) : (
        <>
          {!champions ? (
            <h1 className="noChampions">No champions</h1>
          ) : (
            <>
              <motion.div
                initial="hidden"
                animate="visible"
                variants={cardVariants}
                transition={{ duration: 1.2 }}
              >
                <div className="cards-container">
                  <div className="title-wrapper">
                    <h3>Meet Techlarious Champions</h3>
                  </div>
                  <div className="cards-grid">
                    {champions
                      .slice(
                        (currentPage - 1) * jobsPerPage,
                        currentPage * jobsPerPage
                      )
                      .map((user, index) => (
                        <UserInfo
                          key={index}
                          name={user.championName}
                          imageSrc={user.championImage}
                          field={user.bootcampName}
                          batch={user.batchNumber}
                        />
                      ))}
                  </div>
                </div>
              </motion.div>
              {!isLoading && champions?.length > jobsPerPage && (
                <div className="pagination">
                  <div className="mt-3">
                    <div className="customPagination">
                      <button
                        className="prev"
                        onClick={(e) => {
                          e.preventDefault();
                          if (currentPage === 1) return;
                          handleClick(currentPage - 1);
                        }}
                        disabled={currentPage === 1}
                      >
                        Prev
                      </button>
                      <div className="pages">
                        {pageNumbers.map((pageNumber) => (
                          <button
                            key={pageNumber}
                            className={
                              pageNumber === currentPage
                                ? `white active`
                                : `white`
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              handleClick(pageNumber);
                            }}
                          >
                            {pageNumber}
                          </button>
                        ))}
                      </div>
                      <button
                        className="next"
                        onClick={(e) => {
                          e.preventDefault();
                          if (currentPage === totalPages) return;
                          handleClick(currentPage + 1);
                        }}
                        disabled={currentPage === totalPages}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {!isLoading && <Footer />}
        </>
      )}
    </>
  );
}

export default Champions;
