import React from "react";
import classes from "./overview.module.css";
import { FaCheckCircle } from "react-icons/fa";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

const Overview = ({ course }) => {
  return (
    <>
      <div className={classes.upperSection}>
        <div className={classes.header}>
          <h2>Course Description</h2>
        </div>
        <div className={classes.description}>
          <p>{course?.courseDescription}</p>
        </div>
      </div>
      <div className={classes.lowerSection}>
        <div className={classes.header}>
          <h2>Course Requirements</h2>
        </div>
        <div className={classes.listingTopics}>
          {/* TOPICS */}
          {course?.coursePrerequisites.map((topic, i) => (
            <div key={i} className={classes.singleLearn}>
              <BsFillArrowRightCircleFill
                color="white"
                size={24}
                style={{
                  verticalAlign: "middle",
                  borderRadius: "50%",
                  border: "none",
                }}
              />
              <div className={classes.topic}>
                <p>{topic}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.lowerSection}>
        <div className={classes.header}>
          <h2>What you will Learn</h2>
        </div>
        <div className={classes.listingTopics}>
          {/* TOPICS */}
          {course?.courselearnings.map((topic, i) => (
            <div key={i} className={classes.singleLearn}>
              <div>
                <FaCheckCircle
                  color="white"
                  size={24}
                  style={{
                    verticalAlign: "middle",
                    backgroundColor: "#1B4481",
                    borderRadius: "50%",
                    border: "none",
                  }}
                />
              </div>
              <div className={classes.topic}>
                <p>{topic}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Overview;
