import React, { useState, useEffect } from "react";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import { ModalClose } from "@mui/joy";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  MenuItem,
  Select,
  Box,
  // Modal,
} from "@mui/material";
import classes from "./modal.module.css";
import axios from "../axios/axios";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import DOMPurify from "dompurify";

export default function Modal1({ course, open, setOpen, refresh, id }) {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    fullName: "",
    email: "",
    phone: "",
    country: "",
    experienceLevel: "",
    educationalLevel: "",
    preferredPaymentMethod: "",
  });

  const [countries, setCountries] = useState([]);

  const appliedCoupon =
    JSON.parse(localStorage.getItem("couponDiscount"))?.courseSlug === id
      ? true
      : false;
  const couponDiscount = JSON.parse(localStorage.getItem("couponDiscount"));

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const data = await response.json();
        const sortedCountries = data
          .sort((a, b) => a.name.common.localeCompare(b.name.common))
          .filter((e) => e.name !== "Israel");
        setCountries(sortedCountries);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  // useEffect(() => {
  //   console.clear();
  // }, []);

  const handleSubmit = async (info) => {
    if (form.phone.length < 8) {
      toast.error("Enter a valid phone number", {
        position: "top-center",
        autoClose: 2000,
      });
      return;
    }
    if (form.fullName.trim().length < 3) {
      toast.error("Enter a valid name", {
        position: "top-center",
        autoClose: 2000,
      });
      return;
    }
    if (form.preferredPaymentMethod === "") {
      toast.error("Please select your preferred payment method", {
        position: "top-center",
        autoClose: 2000,
      });
      return;
    }

    try {
      setLoading(true);
      const { data } = await axios.post(
        `/enrollement/enroll/${course?._id}`,
        info
      );
      setLoading(false);
      toast.success(data.message);
      localStorage.removeItem("couponDiscount");
      refresh();
      clearForm();
      setOpen(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };
  const clearForm = () => {
    setForm({
      fullName: "",
      email: "",
      phone: "",
      country: "",
      experienceLevel: "",
      educationalLevel: "",
      preferredPaymentMethod: "",
    });
  };
  return (
    <React.Fragment>
      <Modal
        sx={{
          height: "95vh",
        }}
        open={open}
        onClose={(_event, reason) => {
          if (reason === "backdropClick") {
            return;
          } else {
            setOpen(false);
          }
        }}
        className={classes.modal}
      >
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{
            width: 500,
            overflowY: "scroll",
            zIndex: "999",
            marginTop: "60px",
          }}
          className={classes.mainModal}
        >
          <ModalClose />
          <Typography
            className={classes.headerCourse}
            id="basic-modal-dialog-title"
            component="h1"
          >
            Enroll:{course?.courseName}
          </Typography>

          <form
            onSubmit={(event) => {
              event.preventDefault();
              const formData = {
                studentFullName: form.fullName,
                studentEmail: form.email,
                studentPhoneNumber: form.phone,
                studentCountry: form.country,
                experienceLevel: form.experienceLevel,
                educationLevel: form.educationalLevel,
                paymentMethod: form.preferredPaymentMethod,
                couponCode: appliedCoupon ? couponDiscount.couponCode : "",
              };
              handleSubmit(formData);
            }}
          >
            <Stack spacing={0.5}>
              <FormControl>
                <FormLabel required>Full Name</FormLabel>
                <Input
                  onChange={(e) =>
                    setForm((prevForm) => {
                      return {
                        ...prevForm,
                        fullName: DOMPurify.sanitize(e.target.value),
                      };
                    })
                  }
                  value={form.fullName}
                  autoFocus
                  required
                />
              </FormControl>
              <FormControl>
                <FormLabel required>Email</FormLabel>
                <Input
                  onChange={(e) =>
                    setForm((prevForm) => {
                      return {
                        ...prevForm,
                        email: DOMPurify.sanitize(e.target.value),
                      };
                    })
                  }
                  value={form.email.trim()}
                  type="email"
                  required
                />
              </FormControl>
              <FormControl>
                <FormLabel required>Phone</FormLabel>
                <Input
                  onChange={(e) => {
                    const sanitizedValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
                    setForm({ ...form, phone: sanitizedValue });
                  }}
                  value={form.phone}
                  type="text"
                  required
                />
              </FormControl>
              <FormControl fullWidth>
                <FormLabel required>Country</FormLabel>
                <Select
                  label="Country"
                  required
                  value={form.country}
                  onChange={(e) =>
                    setForm((prevForm) => {
                      return {
                        ...prevForm,
                        country: DOMPurify.sanitize(e.target.value),
                      };
                    })
                  }
                  MenuProps={{
                    style: {
                      maxHeight: 300, // Specify the desired maximum height
                      width: 200, // Specify the desired width
                    },
                  }}
                >
                  {countries.map((country) => (
                    <MenuItem
                      key={country.name.common}
                      value={country.name.common}
                    >
                      {country.name.common}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <FormLabel required>Experience Level</FormLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  required
                  value={form.experienceLevel}
                  onChange={(e) =>
                    setForm((prevForm) => {
                      return {
                        ...prevForm,
                        experienceLevel: DOMPurify.sanitize(e.target.value),
                      };
                    })
                  }
                >
                  <MenuItem value="No Experience">No Experience</MenuItem>
                  <MenuItem value="Entry Level">Entry Level</MenuItem>
                  <MenuItem value="Mid">Mid</MenuItem>
                  <MenuItem value="Intermediate">Intermediate</MenuItem>
                  <MenuItem value="Senior">Senior</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <FormLabel required>Educational Level</FormLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Education level"
                  required
                  value={form.educationalLevel}
                  onChange={(e) =>
                    setForm((prevForm) => {
                      return {
                        ...prevForm,
                        educationalLevel: DOMPurify.sanitize(e.target.value),
                      };
                    })
                  }
                >
                  <MenuItem value="SelfLearner">SelfLearner</MenuItem>
                  <MenuItem value="College Student">College Student</MenuItem>
                  <MenuItem value="HighSchool">High school</MenuItem>
                  <MenuItem value="Undergraduate Student">
                    Undergraduate
                  </MenuItem>
                  <MenuItem value="Graduate Student">Graduate </MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel required>Preferred Payment Method</FormLabel>
                <RadioGroup
                  row
                  onChange={(e) =>
                    setForm((prevForm) => {
                      return {
                        ...prevForm,
                        preferredPaymentMethod: DOMPurify.sanitize(
                          e.target.value
                        ),
                      };
                    })
                  }
                >
                  <FormControlLabel
                    value="Western Union"
                    control={<Radio />}
                    label="Western Union"
                  />
                  <FormControlLabel
                    value="OMT"
                    control={<Radio />}
                    label="OMT"
                  />
                  <FormControlLabel
                    value="Whish"
                    control={<Radio />}
                    label="WHISH"
                  />
                </RadioGroup>
              </FormControl>
              <div className={classes.wrapper}>
                <Box
                  style={{
                    display: "flex",
                    gap: "5px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <h1 style={{ fontSize: "20px" }}>
                      Course fees :{" "}
                      {appliedCoupon && (
                        <span
                          style={{
                            textDecoration: "line-through",
                            color: "red",
                          }}
                        >
                          {course?.coursePrice}${" "}
                        </span>
                      )}
                    </h1>
                  </div>
                  <div>
                    <Typography
                      component="h3"
                      sx={{ color: "green" }}
                      style={{ fontSize: "20px", marginRight: "5px" }}
                    >
                      {appliedCoupon
                        ? couponDiscount.newPrice
                        : course?.courseDiscount !== 0
                        ? course?.coursePrice - course?.courseDiscount
                        : course?.coursePrice}
                      $
                    </Typography>
                  </div>
                </Box>
                <Button type="submit" className={classes.button}>
                  {loading ? <CircularProgress /> : "Submit"}
                </Button>
              </div>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
