import React, { useState, useEffect } from "react";
import { FaCalendar } from "react-icons/fa";
import classes from "./innercard.module.css";
import Modal from "../../modal/Modal";
import axios from "../../axios/axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { BsFillShareFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { IoTicketOutline } from "react-icons/io5";
import DOMPurify from "dompurify";
import { AiOutlineCloseCircle } from "react-icons/ai";
  

function transformDate(dateString) {
  const date = new Date(dateString);
  
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-based, so we add 1
  const year = date.getFullYear();
  
  return `${day}/${month}/${year}`;
}



const InnerCard = ({ course }) => {
  const [applyCoupon, setApplyCoupon] = useState(false);
  const [couponDiscount, setCoupnDiscount] = useState(
    JSON.parse(localStorage.getItem("couponDiscount"))
  );
  const [coupon, setCoupon] = useState("");
  const [model, setOpenModal] = useState(false);

  const { id } = useParams();

  const openModal = (e) => {
    setOpenModal(true);
  };

  const handleCouponSubmit = async () => {
    try {
      const { data } = await axios.post(`/enrollement/apply-coupon/${id}`, {
        couponCode: coupon.trim(),
      });
      localStorage.setItem(
        "couponDiscount",
        JSON.stringify({
          newPrice: data.newPrice,
          couponCode: data.couponCode,
          courseSlug: data.courseSlug,
        })
      );
      setCoupnDiscount({
        newPrice: data.newPrice,
        couponCode: data.couponCode,
      });
      setCoupon("");
      setApplyCoupon(false);
      toast.success(data.message, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      // if (data) {
      //   setOpenModal(true);
      // }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const appliedCoupon =
    JSON.parse(localStorage.getItem("couponDiscount"))?.courseSlug === id
      ? true
      : false;

  const handleCouponInput = (e) => {
    if (!appliedCoupon) {
      setApplyCoupon((prevState) => !prevState);
    }
  };
  const shareableLink = async () => {
    try {
      navigator.clipboard
        .writeText(window.location.href)
        .then(() => {
          toast.success("The course link is copied to your clipboard");
        })
        .catch((error) => {
          console.error(`Error copying to clipboard: ${error}`);
        });
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleRemoveCoupon = async () => {
    localStorage.removeItem("couponDiscount");
    setCoupnDiscount("");
  };

  useEffect(() => {
    if (couponDiscount) {
      handleRemoveCoupon();
    }
  }, []);

  return (
    <>
      <Modal
        course={course}
        open={model}
        setOpen={setOpenModal}
        refresh={setCoupnDiscount}
        id={id}
      />
      <div className={classes.innerCard}>
        <div className={classes.image}>
          <img src={course?.courseImage} alt="course" />
        </div>
        <div className={classes.cardInfo}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className={classes.price}>
              <p className={classes.price1}>
                {course?.courseDiscount
                  ? course?.coursePrice - course?.courseDiscount === 0
                    ? ""
                    : course?.coursePrice - course?.courseDiscount + "$"
                  : course?.coursePrice + "$"}{" "}
              </p>
              {course?.courseDiscount !== 0 &&
                course?.courseDiscount !== course?.coursePrice && (
                  <>
                    <p className={classes.oldPrice}>{course?.coursePrice} $</p>
                    <p className={classes.discount}>
                      {course?.courseDiscount}$ off
                    </p>
                  </>
                )}
              {course?.courseDiscount !== 0 &&
                course?.courseDiscount == course?.coursePrice && (
                  <p className={classes.Free}>Free</p>
                )}
            </div>
            <div className={classes.share}>
              <BsFillShareFill
                sx={{ color: "blue", fontSize: "35px", cursor: "pointer" }}
                onClick={shareableLink}
              />
            </div>
          </div>

          <div className={classes.seats}>
            <IoTicketOutline color="white" fontSize={25} />
            <p>Few Seats Left</p>
          </div>
          <div className={classes.coupon}>
            <div className={classes.date}>
              <FaCalendar color="white" />
              <p>
                Starting Date:{" "}
                {course?.startingDate}
              </p>
            </div>
            <div
              className={appliedCoupon ? classes.applied : classes.couponText}
              onClick={(e) => handleCouponInput(e)}
            >
              {course?.courseDiscount == course?.coursePrice ? (
                ""
              ) : (
                <>
                  <p>
                    {appliedCoupon
                      ? `${couponDiscount.couponCode}  is applied`
                      : " Apply coupon"}
                  </p>
                  {appliedCoupon && (
                    <p className={classes.icon} onClick={handleRemoveCoupon}>
                      <AiOutlineCloseCircle fontSize={17} />
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
          {applyCoupon ? (
            <div className={classes.applyCoupon}>
              <div className={classes.inputWrapper}>
                <input
                  type="text"
                  placeholder="Enter coupon code"
                  onChange={(e) =>
                    setCoupon(DOMPurify.sanitize(e.target.value))
                  }
                  value={coupon.trim()}
                  required
                />
                <button type="submit" onClick={handleCouponSubmit}>
                  Apply
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className={classes.submit}>
            <button type="submit" onClick={openModal}>
              Enroll Now
            </button>
          </div>
          <div className={classes.cardFooter}>
            <Link to={`/financial-aid/${id}`}>
              Installment available! Submit your interest now!
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default InnerCard;
