import React, { useEffect, useState } from "react";
import classes from "./CollectFees.module.css";
import { FormLabel } from "@mui/material";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { toast } from "react-toastify";
import axios from "../../axios/axios";
import { useParams, useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import { CircularProgress } from "@mui/material";
 import NotFound from "../../screens/notFound/alreadySubmitted.jsx";


const CollectFees = () => {
  const textareaStyles = {
    resize: "none",
  };
  const navigate = useNavigate();
  const [enroll, setEnroll] = useState([]);
  const [isLoading,setIsLoading] = useState(false)
 const [isRegistered,setIsRegistered] = useState(false)

  const { id } = useParams();

  const [form, setForm] = useState({
    invoice: {
      name: "",
      url: "",
    },
    note: "",
  });

  const handleInvoice = (e) => {
    const file = e.target.files[0];
    // // Check if the selected file is an image or a PDF
    if (
      file &&
      (file.type.startsWith("image/"))
    ) {
    //   const reader = new FileReader();

      // reader.onload = (event) => {
      //   // Convert the file to base64
      //   const base64Data = event.target.result;
      setForm((prevForm) => ({
        ...prevForm,
        invoice: {
          name: file, 
          url: "",
        },
      }));
      // };

      // reader.readAsDataURL(file);
    } else {
      toast.error("Please upload an image");
    }
  };

  useEffect(() => {
    const getEnroll = async () => {
      try {
        const { data } = await axios.get(`/enrollement/enroll/${id}`);
       if(data.data === 1) setIsRegistered(true)
        setEnroll(data.enrollment);
        
      } catch (error) {
        navigate("/404");
      }
    };
    getEnroll();
  }, [id, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (form.note.trim().length === 0) {
    //   toast.error("Please fill all the fields", {
    //     position: "top-center",
    //     autoClose: 2000,
    //   });
    //   return;
    // }
    setIsLoading(true)
    try {
      // const formData = {
      //   course: enroll?.parentCourse?._id,
      //   name: enroll?.studentFullName,
      //   email: enroll?.studentEmail,
      //   enrollmentDate: enroll?.enrollmentDate,
      //   preferredPaymentMethod: enroll?.paymentMethod,
      //   notes: form.note,
      //   invoice: form.invoice.url,
      // };
     const formData = new FormData();
     formData.append("course", enroll?.parentCourse?._id);
     formData.append("name", enroll?.studentFullName);
     formData.append("email", enroll?.studentEmail);
     formData.append("enrollmentDate", enroll?.enrollmentDate);
     formData.append("preferredPaymentMethod", enroll?.paymentMethod);
     formData.append("notes", form.note);
    
      // Append the file to the FormData object
      formData.append("invoice", form.invoice.name);
      // Send the form data to the server
      await axios.post("/enrollement/submitInvoice", formData);

      // Show success toast notification
      toast.success("Invoice submitted successfully");
      setIsLoading(false)
      // Reset the form fields
      setForm({
        invoice: {
          name: "",
          url: "",
        },
        note: "",
      });
      navigate("/");
    } catch (error) {
      // Show error toast notification
       setIsLoading(false)
      toast.error(error?.response?.data?.message);
    }
  };

 return (
  <>
    <Navbar />
    {isRegistered ? (
      <NotFound />
    ) : (
      <>
        <div className={classes.contactSection}>
          {/* Title */}
          <div className={classes.contact}>
            <h3>
              Fast-track Your Enrollment: Submit Your Course Fees Invoice Today!
            </h3>
          </div>
          <div className={`${classes.container} ${classes.contact__container}`}>
            <form onSubmit={handleSubmit}>
              <FormLabel
                sx={{
                  color: "white",
                }}
              >
                Course
              </FormLabel>
              <input
                type="text"
                name="name"
                value={enroll?.parentCourse?.courseName}
                readOnly
              />
              <FormLabel
                sx={{
                  color: "white",
                }}
              >
                Name
              </FormLabel>
              <input
                type="text"
                name="name"
                value={enroll?.studentFullName}
                readOnly
              />
              <FormLabel
                sx={{
                  color: "white",
                }}
              >
                Email
              </FormLabel>
              <input
                type="email"
                name="email"
                value={enroll?.studentEmail}
                readOnly
              />
              <FormLabel
                sx={{
                  color: "white",
                }}
              >
                Enrollment Date
              </FormLabel>
              <input
                type="text"
                name="date"
                value={new Date(enroll?.enrollmentDate).toUTCString()}
                readOnly
              />
              <FormLabel
                sx={{
                  color: "white",
                }}
              >
                Your preferred payment method
              </FormLabel>
              <input
                type="text"
                name="payment"
                value={enroll?.paymentMethod}
                readOnly
              />
              <FormLabel
                sx={{
                  color: "white",
                }}
                required
              >
                Upload your invoice
              </FormLabel>
              <input
                type="file"
                name="payment"
                onChange={(e) => handleInvoice(e)}
                accept="image/*"
              />

              <FormLabel
                sx={{
                  color: "white",
                  marginBottom: "10px !important",
                }}
              >
                Would you like to provide us with any thoughts or notes?
              </FormLabel>
              <textarea
                name="message"
                id=""
                rows="7"
                style={textareaStyles}
                value={form.message}
                onChange={(e) =>
                  setForm({ ...form, note: DOMPurify.sanitize(e.target.value) })
                }
                placeholder="Why you are interested in taking this course?"
              ></textarea>

              <button
                type="submit"
                className={classes.sendButton}
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress /> : "Submit Your Invoice"}
              </button>
            </form>
          </div>
        </div>
      </>
    )}
    <Footer />
  </>
);

  
};

export default CollectFees;
