import React from "react";
import classes from "./courseRevenue.module.css";
import { BsBook } from "react-icons/bs";
import { GiDuration } from "react-icons/gi";
import { MdLanguage } from "react-icons/md";
import { AiOutlineFieldTime } from "react-icons/ai";
import { TbCertificate } from "react-icons/tb";
import { CgCalendarDates } from "react-icons/cg";


const CourseRevenue = ({ course }) => {
  return (
    <div className={classes.card}>
      <div className={classes.cardHeader}>
        <h1>This course includes</h1>
      </div>
      <div className={classes.cardInfo}>
        <div className={classes.courseLectures}>
          <BsBook color="white" fontSize={25} />
          <p>Lectures</p>
        </div>
        <div className={classes.number}>{course?.lecturesCounter}</div>
      </div>
      <div className={classes.cardInfo}>
        <div className={classes.courseLectures}>
          <GiDuration color="white" fontSize={25} />
          <p>Duration</p>
        </div>
        <div className={classes.number}>{course?.nbOfWeeks} {course?.scale}</div>
      </div>
      <div className={classes.cardInfo}>
        <div className={classes.courseLectures}>
          <CgCalendarDates color="white" fontSize={25} />
          <p>Date</p>
        </div>
        <div className={classes.number}>{course?.courseDate}</div>
      </div>
      <div className={classes.cardInfo}>
        <div className={classes.courseLectures}>
          <AiOutlineFieldTime color="white" fontSize={25} />
          <p>Time</p>
        </div>
        <div className={classes.number}>{course?.courseTime}</div>
      </div>
      <div className={classes.cardInfo}>
        <div className={classes.courseLectures}>
          <MdLanguage color="white" fontSize={25} />
          <p>Language</p>
        </div>
        <div className={classes.number}>
          {course?.courseLanguages.map((c, i) => (
            <p key={i}>{c}</p>
          ))}
        </div>
      </div>

      <div className={classes.cardInfo}>
        <div className={classes.courseLectures}>
          <TbCertificate color="white" fontSize={25} />
          <p>Certificate</p>
        </div>
        <div className={classes.number}>
          {course?.certificationElligible ? <p>Yes</p> : <p>No</p>}
        </div>
      </div>
    </div>
  );
};

export default CourseRevenue;
