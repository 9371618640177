import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { FaMinus, FaPlus } from "react-icons/fa";
import classes from "./cirriculum.module.css";

const Section = ({ index, title, content }) => {
  const [expanded, setExpanded] = React.useState(index === 0 ? true : false);

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleAccordionChange}
      sx={{
        marginBottom: "20px",
        backgroundColor: "#18396c",
        color: "white",
      }}
    >
      <AccordionSummary
        expandIcon={
          <span
            style={{
              transition: "transform 0.3s",
            }}
          >
            {expanded ? <FaMinus color="white" /> : <FaPlus color="white" />}
          </span>
        }
      >
        <Typography variant="h6" className={classes.title}>
          CHAPTER {index + 1}: {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          <ul className={classes.cirriculum}>
            {content?.map((content1) => (
              <li className={classes.content}>{content1}</li>
            ))}
          </ul>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default Section;
